.StatWindow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
}
@media only screen and (min-width: 1000px) {
    .StatWindow {
    min-width: 49vw;
      max-width: 50vw;
    }
  }

.Stats-header {
    display: flex;
    position: sticky;
    top: 5px;
    z-index: 5;
    height: 3.3em;
    margin: 8px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.Stats-header button {
    width: 4em;
    height: 4em;
}

#ChartView {
    display: flex;
    flex-direction: column;
    flex: 10;
    min-height: 336px;
    min-width: 290px;
    margin: 8px;
    padding: 2px;
    align-items: center;
    justify-content: center;
}

#SliderHourView {
    display: flex;
    flex: 0.7;
    margin: 8px;
    padding: 2px;
    align-items: center;
    justify-content: center;
}

#ChosenTimeUnitNavView {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    margin: 8px;
    padding: 2px;
    align-items: stretch;
    width: inherit;
}

#TimeUnitSelectorView {
    display: flex;
    flex: 0.7;
    margin: 8px 8px 16px 8px; 
    padding: 2px;
    align-items: center;
    justify-content: space-around;
}

#VilisVelvetyElementView {
    display: flex;
    border: 1px;
    margin: 2px;
    padding: 2px;
    max-height: 3vh;
    align-items: center;
    justify-content: center;
}
#SliderHourView {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
