.howOthersFeltButton {
        border-style:solid;
        border-width: 1px;
        border-color: rgb(128, 143, 173);
        background-color: transparent;
        color: #ffffff;
        margin: 0px 46px 24px 46px;
        padding: 8px;
        border-radius: 8px;
}
.howOthersFeltButton:hover {
    color:  #3a497b;
    cursor: pointer
}
.emotions {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

/*TABLETVIEW PASSWORD INPUT SCREEN*/
#passwordScreen {
    display: flex;
    flex-direction: column;
}

#passwordInput {
    height: 30px;
    width: 250px;
    margin-bottom: 20px;
}