

.Angry {
    background-color: rgb(255 190 190);
    color: rgb(225, 85, 85);
    animation: fadeIn 1s forwards !important;
  }

  .Happy {
    background-color: rgb(206 255 195);
    color: rgb(61 148 42);
    animation: fadeIn 1s forwards !important;
  }
  
  .Sad {
    background-color: rgb(184, 204, 244);
    color: rgb(63 103 179);
    animation: fadeIn 1s forwards !important;
  }
  
  .Excited {
    background-color: rgb(255, 239, 153);
    color: rgb(176, 148, 0);
    animation: fadeIn 1s forwards !important;
  }
  .Scared {
    background-color: rgb(243, 189, 255);
    color: rgb(127 62 159);
    animation: fadeIn 1s forwards !important;
 }
  .Neutral {
    background-color: rgb(215, 215, 215);
    color: rgb(59,71,97);
    animation: fadeIn 1s forwards !important;
}



@keyframes fadeOut {
    0% {opacity: 1;}
    100% {background-color: transparent;} 
 }

 @keyframes fadeIn {
    100% {background-color: background-color;}
    0% {background-color: transparent;} 
 }

 .Angry-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(255, 190, 190, 0);
    color: rgb(225, 85, 85);
    
 }

  .Happy-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(206, 255, 195, 0);
    color: rgb(61 148 42);
    
  }
  
  .Sad-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(184, 204, 244, 0);
    color: rgb(63 103 179);
    
  }
  
  .Excited-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(255, 239, 153, 0);
    color: rgb(176, 148, 0);
    
  }
  .Scared-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(243, 189, 255, 0);
    color: rgb(127 62 159);
    

 }
  .Neutral-disabled:disabled {
    cursor: not-allowed;
    background-color: rgba(255, 227, 202, 0);
    color: rgb(59,71,97);
    

}

          /*#Angry-clicked {
              background-color: rgb(254, 103, 103);
              color: rgb(255, 255, 255);


            }

            #Happy-clicked {
              background-color: rgb(82 196 56);
              color: rgb(255, 255, 255);
            }
            
            #Sad-clicked {
              background-color: rgb(91, 146, 255);
              color: rgb(255, 255, 255);
            }
            
            #Excited-clicked {
              background-color: rgb(255, 225, 57);
              color: rgb(255, 255, 255);
            }
            #Scared-clicked {
              background-color: rgb(229, 112, 255);
              color: rgb(255, 255, 255);
          }
            #Neutral-clicked {
              background-color: rgb(255, 171, 97);
              color: rgb(255, 255, 255);
          }*/



.Angry:hover {
    background-color: rgb(236, 135, 135);
    
  }

  .Happy:hover {
    background-color: rgb(152, 190, 143);
  }
  
  .Sad:hover {
    background-color: rgb(128, 143, 173);
  }
  
  .Excited:hover {
    background-color: rgb(251, 229, 104);
  }
  .Scared:hover {
    background-color: rgb(195, 130, 212);
  }
  .Neutral:hover {
    background-color: rgb(132, 132, 132);
  }

.emotion-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.emotion-buttons button {
    padding: 8px;
    margin: 8px;
    /* border-style: none; */
    border-radius: 8px;
    width: 90px;
    height: 80px;
}
/* .emotion-buttons button:not([enabled]) {
    animation: fadeOut 1s forwards;
} */

.emotion-buttons button:not([disabled]):active {
    width: 95px;
    height: 85px;
}

.EmotionButton-button {
    padding: 8px;
    margin: 8px;
    border-style: none;
    border-radius: 8px;
    width: 90px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }



  .EmotionButton-button-label {
    display: flex;
    flex-direction: column;
  }
.content {
    display: flex;
    flex-direction: column;
}

.infoText {
    font-size: 0.6em;
    color:rgb(128, 143, 173)
}
