.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 8px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 83% !important; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    left: 0;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }    

  .range-text {
    font-size: 0.7em;
  }
  
  .hourRange-singleHour {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    
  }
